import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => {
  return <Layout>
            <SEO title='About' />
            {children}
        </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`Hi! Thanks for taking the time to check out my site!`}</p>
    <p>{`I am a Software Engineer with a passion for software development.
My current language of choice is TypeScript, and am starting to
dive more into Rust.`}</p>
    <p>{`I live and work in West Michigan with my wife and three kids. I enjoy
spending time with my family and friends (but who doesn't), a good
podcast (Syntax.fm and Software Engineering Daily are a couple of my
favorites), and exercising (no seriously).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      